import "../style/waypoints.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEraser, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { DeleteWay } from "./waypoints/deleteWaypoint";
import { ShowQR } from "./locations/showQR";
import { EditLocations } from "./locations/editLocations";
import React from 'react';
import axios from "axios";

export const LocationList = ({ waypoints, handleRefresh, loadingNow }) => {
    const [delWay, setDelete] = useState(false);
    const [editWay, setEdit] = useState(false);
    const [qrShow, setQR] = useState(false);
    const [data, setData] = useState('');
    const [selected, setSelected] = useState(null);

    const showDelete = (x) => {
        setDelete(current => !current);
        setSelected(x.id);
    };

    const handleDelete = async => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/landing/landing_points/${selected}`, {
            withCredentials: true,
            headers: {
                'ngrok-skip-browser-warning': true,
            }
            }).then(() => {
                handleRefresh();
                setDelete(false);
            }).catch((error) => {
                console.error(error);
            })
    };

    const showEdit = (x) => {
        setEdit(current => !current);
        setData(x);
    };

    const showQR = (x) => {
        setQR(current => !current);
        setData(x);
    };

    return (
        <div className="listWaypoints">
            <table className="tabelDekstopWaypoint">
                <thead>
                    <tr>
                        <th className="idWay">ID</th>
                        <th className="nameWay">Name</th>
                        <th className="latWay">Latitude</th>
                        <th className="longWay">Longitude</th>
                        <th className="latWay">Altitude</th>
                        <th className="locationDescription">Description</th>
                        <th>Settings</th>
                    </tr>
                </thead>
                <tbody>
                    {loadingNow ? 
                        <div className="loaderLogin listLoader"></div> 
                        : waypoints.map(x => {
                            return (
                                <tr key={x.id}>
                                    <td className="idWay">{x.id}</td>
                                    <td>{x.name}</td>
                                    <td>{parseFloat(x.latitude).toFixed(5)}</td>
                                    <td>{parseFloat(x.longitude).toFixed(5)}</td>
                                    <td>{x.altitude}</td>
                                    <td>{x.description}</td>
                                    <td className="actionButtonWay">
                                        <div className="dropdown">
                                            <button className="ellipsisButton">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </button>
                                            <div className="dropdown-content">
                                                <button className={x.status === 1 ? "deleteButtonWay" : "enabledButton"} onClick={() => showQR(x)}>
                                                    {x.status === 1 ? "Disabled" : "View Marker"}
                                                </button>
                                                <button className="editButtonWay" onClick={() => showEdit(x)}>
                                                    <FontAwesomeIcon icon={faPencil} /> Edit
                                                </button>
                                                <button className="deleteButtonWay" onClick={() => showDelete(x)}>
                                                    <FontAwesomeIcon icon={faEraser} /> Delete
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>

            {delWay && <DeleteWay handleClick={showDelete} handleConfirm={handleDelete}></DeleteWay>}
            {editWay && <EditLocations handleClick={showEdit} data={data} handleRefresh={handleRefresh}></EditLocations>}
            {qrShow && <ShowQR handleClick={showQR} data={data} handleRefresh={handleRefresh}></ShowQR>}
        </div>
    );
};
