import "../../style/addtask.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useState } from "react";
import React from 'react';
import baseUrl from '../../config.js';

export const ShowQR = ({ handleClick, data, handleRefresh }) => {
    const [status, setStatus] = useState();

    const deleteTask = async (e) => {
        e.preventDefault();

        const statusnya = data.status === 1 ? 0 : 1;

        try {
            const response = await axios.patch(`${baseUrl}/waypoints/${data.id}`, {
                name: data.name,
                code: data.code,
                latitude: data.latitude,
                longitude: data.longitude,
                altitude: data.altitude,
                status: statusnya,
            });

            if (response.status === 200) {
                handleClick();
                handleRefresh();
            }
        } catch (error) {
            console.log(error.response.data);
        }
    }

    return (
        <div className="qrDialog">
            <div className="formDelete">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark} className="logoExit" />
                </button>
                <div className="mainContent-delete">
                    {/* Display the QR code */}
                    <img src="aruco_2.png" alt="QR Code" className="qrImage" />
                    {/* You can also display the name or any other relevant info here */}
                </div>
            </div>
        </div>
    )
}
