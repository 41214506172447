import "../style/waypoints.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEraser, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { DeleteWay } from "./waypoints/deleteWaypoint";
import { EditWaypoints } from "./waypoints/editWaypoints";
import React from 'react';
import axios from "axios";

const ActionButtons = ({ showEdit, showDelete, x }) => (
    <div>
        <button className="editButtonWay" onClick={() => showEdit(x)}>
            <FontAwesomeIcon icon={faPencil} /> Edit
        </button>
        <button className="deleteButtonWay" onClick={() => showDelete(x)}>
            <FontAwesomeIcon icon={faEraser} /> Delete
        </button>
    </div>
);

export const WaypointList = ({ waypoints, handleRefresh, loadingNow }) => {
    const [delWay, setDelete] = useState(false);
    const [editWay, setEdit] = useState(false);
    const [data, setData] = useState('');
    const [selected, setSelected] = useState(null);

    const showDelete = (x) => {
        setDelete(current => !current);
        setSelected(x.id);
    };

    const handleDelete = async => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/waypoint/waypoints/${selected}`, {
            withCredentials: true,
            headers: {
                'ngrok-skip-browser-warning': true,
            }
            }).then(() => {
                handleRefresh();
            }).catch((error) => {
                console.error(error);
            }
        )
        setDelete(false);
    };

    const showEdit = (x) => {
        setEdit(current => !current);
        setData(x);
    };

    return (
        <div className="listWaypoints">
            <table className="tabelDekstopWaypoint">
                <thead>
                    <tr>
                        <th className="idWay">ID</th>
                        <th className="nameWay">Name</th>
                        <th className="latWay">Source</th>
                        <th className="latWay">Destination</th>
                        <th>Settings</th>
                    </tr>
                </thead>
                <tbody>
                    {loadingNow ? (
                        <tr>
                            <td colSpan="5" className="loaderLogin listLoader"></td>
                        </tr>
                    ) : (
                        waypoints.map(x => (
                            <tr key={x.id}>
                                <td className="idWay">{x.id}</td>
                                <td>{x.name}</td>
                                <td>{x.source}</td>
                                <td>{x.destination}</td>
                                <td className="actionButtonWay">
                                <div className="dropdown">
                                            <button className="ellipsisButton">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </button>
                                            <div className="dropdown-content">
                                            <button className="editButtonWay" onClick={() => showEdit(x)}>
                                                <FontAwesomeIcon icon={faPencil} /> Edit
                                            </button>
                                            <button className="deleteButtonWay" onClick={() => showDelete(x)}>
                                                <FontAwesomeIcon icon={faEraser} /> Delete
                                            </button>
                                            </div>
                                        </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            <div className="tabelMobileWaypoint">
                {waypoints.map(x => (
                    <div className="dataRow" key={x.id}>
                        <table>
                            <tr>
                                <td className="titleRow">ID Waypoint</td>
                                <td className="rowValue">{x.id}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Name</td>
                                <td className="rowValue">{x.name}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Code</td>
                                <td className="rowValue">{x.code}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Latitude</td>
                                <td className="rowValue">{x.latitude}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Longitude</td>
                                <td className="rowValue">{x.longitude}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Altitude</td>
                                <td className="rowValue">{x.altitude}</td>
                            </tr>
                            <tr>
                                <td className="titleRow">Action Button</td>
                                <td className="rowValue">
                                    <ActionButtons showEdit={showEdit} showDelete={showDelete} x={x} />
                                </td>
                            </tr>
                        </table>
                    </div>
                ))}
            </div>

            {delWay && <DeleteWay handleClick={showDelete} handleConfirm={handleDelete} />}
            {editWay && <EditWaypoints handleClick={showEdit} data={data} handleRefresh={handleRefresh} />}
        </div>
    );
};
