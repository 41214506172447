import { useEffect, useState } from "react";
import "../../style/addWaypoints.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import React from 'react';
import baseUrl from '../../config.js';

export const AddWaypoints = ({ handleClick, handleRefresh }) => {
    const [name, setName] = useState('');
    const [sourceId, setSourceId] = useState('');
    const [destinationId, setDestinationId] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locations, setLocations] = useState([]); // Stores fetched locations

    useEffect(() => {
        // Fetch locations from the API
        const fetchLocations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/landing/landing_points`, {
                    headers: {
                        'ngrok-skip-browser-warning': true,  // Add this custom header
                    }
                });
                setLocations(response.data.data); // Update with the list of locations
            } catch (error) {
                console.log("Error fetching locations:", error);
            }
        };
        fetchLocations();
    }, []);

    const addWaypoints = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/waypoint/waypoints`, {
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                },
                name: name,
                source_id: sourceId,
                destination_id: destinationId,
            });

            if (response.status === 201) {
                handleRefresh();
                handleClick(); // Close dialog on success
            }
        } catch (error) {
            console.log("Error adding waypoint:", error.response?.data);
        }
    };

    const mapClicked = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
    };

    return (
        <div className="updateTask">
            <div className="formTask">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark} className="logoExit" />
                </button>

                <div className="mainContent-update">
                    <h1>Add New Waypoint</h1>
                    <form className="formAddWay" onSubmit={addWaypoints}>
                        <table className="tabelEdit">
                            <tbody>
                                <tr className="latInput">
                                    <td>
                                        <h2 className="labelField labelWay">1. Waypoint Name</h2>
                                        <input
                                            type="text"
                                            placeholder="Input Name"
                                            required
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="labelField labelWay">2. Source Location</h2>
                                        <select
                                            required
                                            value={sourceId}
                                            onChange={(e) => setSourceId(e.target.value)}
                                        >
                                            <option value="" disabled>Select Source</option>
                                            {locations.map((location) => (
                                                <option key={location.id} value={location.id}>
                                                    {location.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="labelField labelWay">3. Destination Location</h2>
                                        <select
                                            required
                                            value={destinationId}
                                            onChange={(e) => setDestinationId(e.target.value)}
                                        >
                                            <option value="" disabled>Select Destination</option>
                                            {locations.map((location) => (
                                                <option key={location.id} value={location.id}>
                                                    {location.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="buttonSection">
                                        <input type="submit" value="Submit" />
                                        <button type="button" className="cancelButton" onClick={handleClick}>
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    );
};
