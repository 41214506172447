import { useEffect, useState } from "react";
import { CurrentMission } from "../components/currentMission";
import { Location } from "../components/location";
import { Sidebar } from "../components/sidebar";
import { Status } from "../components/status";
import { videoFeed } from "../components/videoFeed";

import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import "../style/dashboard.css";
import React from 'react';
import baseUrl from '../config.js';
import { io } from "socket.io-client";

export const Dashboard = () =>{

    const [isLoggedIn, setLogged] = useState(true);

    useEffect(() => {
        const socket = io(process.env.REACT_APP_API_URL); 
    
        socket.on('delivery_scheduled', (data) => {
          console.log(data);
        });
      });
    // session check by session storage
    const checkSession = () =>{
        const sessionStatus = sessionStorage.getItem("userData");

        // if(sessionStatus!==null && sessionStatus!==''){
        //     setLogged(true);
        // }
        // else{
        //     window.location.replace('/');
        // }
    }

    // set token function on cookies
    const refreshToken = async() => {
        try {
            // const response = await axios.get(`${baseUrl}/token`,{withCredentials: true});
            // const decode = jwt_decode(response.data.accessToken);
	        // const refreshToken = response.data.refreshToken;
		
	        // Cookies.set('refreshToken', response.data.refreshToken, { secure: true });
		    // sessionStorage.setItem('userData', JSON.stringify(decode));
	        // document.cookie = `refreshToken=${refreshToken}`;

        } catch (error) {
            console.log(error);
        }
    }

    // async function execution
    useEffect(() =>{
        refreshToken();
        checkSession();
    }, []);
    
    return(
        <div className="dashboard">
            {isLoggedIn &&
                <div>
                    <Sidebar></Sidebar>

                    <div className="contentDashboard">
                    <div className="topDashboard">
                        <div className="titleDashboard">
                            <h1 className="titleTeks">Dashboard</h1>
                        </div>
                        <Status></Status>
                    </div>
                    <Location></Location>
                    <CurrentMission></CurrentMission>
                    </div>
                </div>
            }
             
        </div>
    )
}
