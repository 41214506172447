import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Login } from './page/Login';
import { Register } from './page/Register';
import { Dashboard } from './page/Dashboard';
import { Waypoints } from './page/Waypoints';
import { Delivery } from './page/Delivery';
import { Locations } from './page/Locations';

import { useEffect, useState, useCallback, useRef } from 'react';
import { setupSocketListeners } from './utils/socketHandler';
import { useNotificationStore } from './store/notificationStore';
import io from 'socket.io-client'; // Import the socket.io client library

import ROSLIB from 'roslib';

const socket = io.connect(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
  extraHeaders: {
    'ngrok-skip-browser-warning': 'true', // Skip the ngrok warning
  },
});

const App = () => {
  const [connected, setConnected] = useState(false);
  const [ros, setRos] = useState(null);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const reconnectTimeoutRef = useRef(null);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_INTERVAL = 5000;

  const clearReconnectTimeout = () => {
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  };

  const initRosConnection = useCallback(() => {
    // Clear any existing connection
    if (ros) {
      ros.close();
    }

    const newRos = new ROSLIB.Ros({
      url: process.env.REACT_APP_ROS_URL
    });

    newRos.on('error', (error) => {
      console.error("ROS connection error:", error);
      setConnected(false);
      
      clearReconnectTimeout();
      
      if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
        reconnectTimeoutRef.current = setTimeout(() => {
          console.log(`Attempting to reconnect (${reconnectAttempt + 1}/${MAX_RECONNECT_ATTEMPTS})...`);
          setReconnectAttempt(prev => prev + 1);
          initRosConnection();
        }, RECONNECT_INTERVAL);
      } else {
        toast.error('Failed to connect to ROS after multiple attempts', {
          position: 'top-right',
          autoClose: false,
        });
      }
    });

    newRos.on('connection', () => {
      console.log("Connected to ROS");
      setConnected(true);
      setReconnectAttempt(0);
      clearReconnectTimeout();
    });

    newRos.on('close', () => {
      console.log("ROS connection closed");
      setConnected(false);
      
      clearReconnectTimeout();
      
      if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
        reconnectTimeoutRef.current = setTimeout(() => {
          console.log(`Connection closed. Attempting to reconnect...`);
          initRosConnection();
        }, RECONNECT_INTERVAL);
      }
    });

    setRos(newRos);
    return newRos;
  }, [reconnectAttempt, ros]);

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_API_URL, {
      transports: ['websocket'],
      extraHeaders: {
        'ngrok-skip-browser-warning': 'true',
      },
    });

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('connect_error', (err) => {
      console.log('Connection error:', err);
      toast.error('Server connection error', {
        position: 'top-right',
        autoClose: false,
      });
    });

    socket.on('delivery_scheduled', (data) => {
      toast.success(data.message, {
        position: 'top-right',
        autoClose: false,
      });
    });

    socket.on('drone_error', (data) => {
      toast.error(data.message, {
        position: 'top-right',
        autoClose: false,
      });
    });

    const rosInstance = initRosConnection();

    return () => {
      socket.disconnect();
      if (rosInstance) {
        rosInstance.close();
      }
      clearReconnectTimeout();
    };
  }, [initRosConnection]);

  return (
    <div>
      {!connected && (
        <div className="fixed top-0 w-full bg-red-500 text-white p-2 text-center z-50">
          {/* <b>Warning:</b> Drone is offline. Any schedules or deliveries will be aborted.
          {reconnectAttempt > 0 && (
            <span className="ml-2">
              (Reconnection attempt {reconnectAttempt}/{MAX_RECONNECT_ATTEMPTS})
            </span>
          )} */}
        </div>
      )}
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/waypoint" element={<Waypoints />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/locations" element={<Locations />} />
      </Routes>
    </div>
  );
};

export default App;