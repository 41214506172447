import React, { useEffect, useState, useRef } from "react";
import ROSLIB from "roslib";
import io from 'socket.io-client';
import refresh from "../assets/refresh.png";
import "../style/status.css";

export const Status = () => {
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [armed, setArmed] = useState(false);
    const [status, setStatus] = useState("Onboard");
    const [connectionClass, setConnectionClass] = useState("boxInternetInactive");
    const [networkMode, setNetworkMode] = useState("4G");
    const [rssi, setRssi] = useState(null);
    const [reconnectAttempt, setReconnectAttempt] = useState(0);
    
    const rosRef = useRef(null);
    const topicRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const MAX_RECONNECT_ATTEMPTS = 5;
    const RECONNECT_INTERVAL = 5000;

    const clearReconnectTimeout = () => {
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
            reconnectTimeoutRef.current = null;
        }
    };

    const initConnection = () => {
        // Clear existing connections
        if (rosRef.current) {
            if (topicRef.current) {
                topicRef.current.unsubscribe();
            }
            rosRef.current.close();
        }

        // Initialize new ROS connection
        const ros = new ROSLIB.Ros({
            url: process.env.REACT_APP_ROS_URL,
            transportLibrary: 'websocket',
            retries: 0, // We'll handle retries ourselves
            transportOptions: {
                websocket: {
                    // Reduce resource usage
                    maxReceivedFrameSize: 100000,
                    maxReceivedMessageSize: 100000,
                    compression: 'none'
                }
            }
        });

        rosRef.current = ros;

        ros.on('connection', () => {
            console.log('Connected to WebSocket server.');
            setConnected(true);
            setConnectionClass("boxInternetActive");
            setLoading(true);
            setReconnectAttempt(0);
            clearReconnectTimeout();
            initTopicSubscription(ros);
        });

        ros.on('error', (error) => {
            console.error("Error connecting to WebSocket:", error);
            handleConnectionFailure();
        });

        ros.on('close', () => {
            console.log('Connection closed.');
            handleConnectionFailure();
        });
    };

    const handleConnectionFailure = () => {
        setConnected(false);
        setConnectionClass("boxInternetInactive");
        setLoading(false);

        clearReconnectTimeout();

        if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
            reconnectTimeoutRef.current = setTimeout(() => {
                console.log(`Attempting to reconnect (${reconnectAttempt + 1}/${MAX_RECONNECT_ATTEMPTS})...`);
                setReconnectAttempt(prev => prev + 1);
                initConnection();
            }, RECONNECT_INTERVAL);
        }
    };

    const initTopicSubscription = (ros) => {
        if (topicRef.current) {
            topicRef.current.unsubscribe();
        }

        const mavrosStateTopic = new ROSLIB.Topic({
            ros: ros,
            name: '/webtopic',
            messageType: 'std_msgs/String',
            compression: 'none',
            throttle_rate: 10000 // Limit update rate to 1 per second
        });

        topicRef.current = mavrosStateTopic;

        mavrosStateTopic.subscribe((message) => {
            try {
                const parsedMessage = JSON.parse(message.data);
                const { position, speed, armed, network_mode, rssi } = parsedMessage;

                setArmed(armed);
                setStatus(armed ? "Onflight" : "Onboard");
                setNetworkMode(network_mode === "FDD LTE" ? "4G" : network_mode);
                setRssi(rssi);
            } catch (error) {
                console.error("Error parsing message:", error);
            }
        });
    };

    // Manual reconnect function
    const handleManualReconnect = () => {
        setReconnectAttempt(0);
        clearReconnectTimeout();
        initConnection();
    };

    useEffect(() => {
        initConnection();

        return () => {
            clearReconnectTimeout();
            if (topicRef.current) {
                topicRef.current.unsubscribe();
            }
            if (rosRef.current) {
                rosRef.current.close();
            }
        };
    }, []);

    return (
        <div className="statusConn">
            <div className="botSection">
                <div className="botStatus">
                    <div className="boxStatusCircle">
                        <h3>Connectivity</h3>
                        <div className={connectionClass}>
                            {!connected && reconnectAttempt > 0 && (
                                <span className="reconnect-attempts">
                                    {reconnectAttempt}/{MAX_RECONNECT_ATTEMPTS}
                                </span>
                            )}
                        </div>  
                    </div>

                    <div className="boxStatus">
                        <h3>GSM</h3>
                        <div className="box">
                            <h4>{connected ? networkMode : "N/A"}</h4>
                        </div>
                    </div>

                    <div className="boxStatus">
                        <h3>Strength</h3>
                        <div className="box-bar">
                            {connected ? (
                                <>
                                    <div className="bar-1-active-four"></div>
                                    <div className="bar-2-active-four"></div>
                                    <div className="bar-3-active-four"></div>
                                    <div className="bar-4-active-four"></div>
                                </>
                            ) : (
                                <h4>N/A</h4>
                            )}
                        </div>
                    </div>

                    <div className="boxStatus">
                        <h3>Status</h3>
                        <div className="boxPosition">
                            <h4>{status}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};