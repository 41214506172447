import { useEffect, useState } from "react";
import "../../style/addWaypoints.css"; // You can use the same styles as AddWaypoints
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import React from 'react';
import baseUrl from '../../config.js';

export const EditWaypoints = ({ handleClick, data, handleRefresh }) => {
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [altitude, setAltitude] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (data) {
            setName(data.name);
            setCode(data.code);
            setLatitude(data.latitude);
            setLongitude(data.longitude);
            setAltitude(data.altitude);
            setStatus(data.status);
        }
    }, [data]);

    const updateWaypoint = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${baseUrl}/waypoints/${data.id}`, {
                name,
                code,
                latitude,
                longitude,
                altitude,
                status,
            });

            if (response.status === 200) {
                handleRefresh();
                handleClick(); // Close the modal
            }
        } catch (error) {
            console.log(error.response.data);
        }
    };

    return (
        <div className="updateTask">
            <div className="formTask">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark} className="logoExit" />
                </button>

                <div className="mainContent-update">
                    <h1>Edit Waypoint</h1>
                    <form className="formAddWay" onSubmit={updateWaypoint}>
                        <table className="tabelEdit">
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">1. Waypoint Name</h2>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">2. Source Location</h2>
                                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="" disabled>Select Source</option>
                                        <option value={4}>Gudang Astra A</option>
                                        <option value={3}>Gudang Astra B</option>
                                        <option value={2}>Gudang Astra C</option>
                                        <option value={1}>Gudang Astra D</option>
                                        <option value={0}>Gudang Astra E</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">3. Destination Location</h2>
                                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="" disabled>Select Destination</option>
                                        <option value={4}>Gudang Astra E</option>
                                        <option value={3}>Gudang Astra D</option>
                                        <option value={2}>Gudang Astra C</option>
                                        <option value={1}>Gudang Astra B</option>
                                        <option value={0}>Gudang Astra A</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="buttonSection">
                                    <input type="submit" value="Update" />
                                    <button className="cancelButton" onClick={handleClick}>Cancel</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    );
};
