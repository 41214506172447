import React from 'react';
import { AddManual } from "./addMenu/addManual";

export const EditTask = ({ handleClose, handleClick, task }) => {
    return (
        <div className="addTask">
            <div className="formTask">
                <AddManual 
                    handleClick={handleClick}
                    handleClose={handleClose}
                    task={task}
                />
            </div>
        </div>
    );
};