import React, { useEffect, useState } from "react";
import axios from "axios";
import ROSLIB from "roslib";
import "../style/location.css";
import MapMoving from "./mapMoving";

export const Location = () => {
    const [altitudeNow, setAltitude] = useState(0);
    const [connectionError, setConnectionError] = useState(null);
    const [conn, setConn] = useState(null); // State to hold the connection

    const createNewConnection = async () => {
        try {
            const newConn = new window.rtcbot.RTCConnection(); // New connection on every page load

            newConn.video.subscribe((stream) => {
                document.querySelector("video").srcObject = stream;
            });

            let offer = await newConn.getLocalDescription(); // Generate offer

            // Send the offer to the server for SDP negotiation
            let response = await axios.post(
                `${process.env.REACT_APP_RTC_URL}/connect`,
                offer,
                { cache: "no-cache" }
            );

            // Apply the server response (answer) to the local connection
            await newConn.setRemoteDescription(response.data);
            console.log("RTC connection established!");
            setConnectionError(null);
            setConn(newConn); // Update state with new connection

            // Handle connection close event
            newConn.onclose = () => {
                console.log("RTC connection closed, attempting to reconnect...");
                setConn(null); // Reset connection state
                reconnect(); // Attempt to reconnect
            };

        } catch (error) {
            console.error("Failed to establish RTC connection:", error);
        }
    };

    const reconnect = async () => {
        setTimeout(() => {
            console.log("Reconnecting RTC...");
            createNewConnection();
        }, 5000); // Retry after 5 seconds
    };

    useEffect(() => {
        createNewConnection(); // Create a new RTC connection on page load

        // Set up ROS connection for altitude
        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}` // Use your WebSocket URL
        });

        ros.on('connection', () => {
            console.log('Connected to ROS WebSocket');
        });

        ros.on('error', (error) => {
            console.error('Error connecting to WebSocket:', error);
            setConnectionError('Failed to connect to ROS');
        });

        ros.on('close', () => {
            console.log('Connection to ROS WebSocket closed');
        });

        // Subscribe to the /mavros/global_position/local topic to receive altitude updates
        const altitudeTopic = new ROSLIB.Topic({
            ros: ros,
            name: "/mavros/global_position/local",
            messageType: "geometry_msgs/PoseWithCovarianceStamped"
        });

        altitudeTopic.subscribe((message) => {
            const altitude = message.pose.pose.position.z;
            setAltitude(altitude.toFixed(2)); // Set altitude to 2 decimal places
            console.log("Current altitude:", altitude);
        });

        return () => {
            if (conn) {
                conn.close();
                console.log("RTC connection closed.");
            }
            altitudeTopic.unsubscribe();
            ros.close();
        };
    }, []); // Empty dependency array ensures this only runs on mount/unmount

    return (
        <div className="droneLocation">
            <div className="viewDrone">
                <h3>Drone View</h3>

                <div className="viewVideo">
                    <video className="myVideo" autoPlay playsInline muted controls></video>
                </div>
            </div>

            <div className="viewMap">
                <div className="titleMapDrone">
                    <h3>Drone Location</h3>
                    <h4 className="curr_alt">Alt : {altitudeNow} m</h4>
                </div>

                <div className="viewLocation">
                    <MapMoving initialZoom={18} />
                </div>
            </div>
        </div>
    );
};
