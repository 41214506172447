import logo from './logo.svg';
import { Route, Routes } from "react-router-dom";
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Login } from './page/Login';
import { Register } from './page/Register';
import { Dashboard } from './page/Dashboard';
import { Waypoints } from './page/Waypoints';
import { Delivery } from './page/Delivery';
import { Locations } from './page/Locations';

import { useEffect, useState } from 'react';
import { setupSocketListeners } from './utils/socketHandler';
import { useNotificationStore } from './store/notificationStore';
import io from 'socket.io-client'; // Import the socket.io client library

import ROSLIB from 'roslib';

const socket = io.connect(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
  extraHeaders: {
    'ngrok-skip-browser-warning': 'true', // Skip the ngrok warning
  },
});

function App() {
  const notifications = useNotificationStore((state) => state.notifications);

  const [ connected, setConnected ] = useState(false);

  useEffect(() => {
    function onConnect() {
      console.log('Connected to server');
    }
  
    socket.on('connect', onConnect);

    socket.on('connect_error', (err) => {
      console.log('Connection error:', err);
    });    

    socket.on('delivery_scheduled', (data) => {
      console.log('Delivery scheduled:', data);
      // Show toast notification when the event is triggered
      toast(`${data.message}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: 'green', // Red background
          color: 'white', // White text
        },
      });
    });

    socket.on('drone_error', (data) => {
      toast(`${data.message}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: 'red', // Red background
          color: 'white', // White text
        },
      });
    });

      const ros = new ROSLIB.Ros({
          url: `${process.env.REACT_APP_ROS_URL}`
      });

      ros.on('error', (error) => {
          console.error("ROS connection error:", error);
          setConnected(false);
      });

      ros.on('connection', () => {
          console.log("Connected to ROS");
          setConnected(true);
      });

      ros.on('close', () => {
          console.log("ROS connection closed");
          setConnected(false);
      });

      const locationTopic = new ROSLIB.Topic({
          ros: ros,
          name: "/webtopic",
          messageType: "std_msgs/String"
      });

    return () => {
      socket.off('connect', onConnect);
      socket.off('delivery_scheduled');  // Cleanup the listener when the component unmounts
      socket.off('drone_error');  // Cleanup the listener when the component unmounts
      locationTopic.unsubscribe();
      ros.close();
    }
  }, []);  // Empty dependency array ensures the effect runs only once when the component mounts

  return (
    <div>
      {!connected && (
        <div className="warning-banner">
          <b>Warning:</b> Drone is offline. Any schedules or deliveries will be aborted.
        </div>
      )}
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/register' element={<Register />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/waypoint' element={<Waypoints />} />
        <Route path='/delivery' element={<Delivery />} />
        <Route path='/locations' element={<Locations />} />
      </Routes>
    </div>
  );
}

export default App;