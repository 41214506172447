import { create } from 'zustand';
import ROSLIB from 'roslib';

const useDroneStore = create((set) => {
    // Initialize the state
    const initialState = {
        coordinates: null, // Default coordinates state
        isRosConnected: false, // Track ROS connection status
        rosConnectionError: null, // Track any errors during connection
    };

    // Create ROS instance
    const ros = new ROSLIB.Ros({
        url: process.env.REACT_APP_ROS_URL, // Replace with your WebSocket URL
    });

    // Event listeners for connection status
    ros.on('connection', () => {
        console.log('Connected to ROS');
        set({ isRosConnected: true, rosConnectionError: null }); // Update state when connected
    });

    ros.on('error', (error) => {
        console.error('ROS connection error:', error);
        set({ isRosConnected: false, rosConnectionError: error.message }); // Update state when error occurs
    });

    ros.on('close', () => {
        console.log('ROS connection closed');
        set({ isRosConnected: false, rosConnectionError: 'Connection closed' }); // Update state on connection close
    });

    // Define the topic
    const gpsTopic = new ROSLIB.Topic({
        ros,
        name: '/mavros/global_position/global',
        messageType: 'sensor_msgs/NavSatFix',
    });

    // Subscribe to the topic and update the state
    gpsTopic.subscribe((message) => {
        const { latitude, longitude } = message;
        set({ coordinates: [latitude, longitude] }); // Update the state
    });

    // Cleanup logic (optional if you manually handle it elsewhere)
    return {
        ...initialState,
        unsubscribe: () => gpsTopic.unsubscribe(), // Provide a cleanup method
    };
});

export default useDroneStore;
