import "../style/listTask.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEraser, faInfoCircle, faEllipsisH, faRocket, faTimes } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import axios from "axios";
import ROSLIB from "roslib";
import baseUrl from '../config.js';
import { LaunchConfirmation } from "./task/launchConfirmation.js";
import { InfoTask } from './task/infoTask';
import { EditTask } from './task/editTask';

export const ListTask = ({ handleClick, handleRefresh }) => {
    const [tasks, setTasks] = useState([]);
    const [loadingList, setLoading] = useState(true);
    const [emptyCheck, setEmpty] = useState(false);
    const [infoPop, setInfo] = useState(false);
    const [editPop, setEditPop] = useState(false);
    const [launchConfirmPop, setLaunchPop] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null); 
    const [selectedEditTask, setSelectedEditTask] = useState(null); 
    const [vehiclePosition, setVehiclePosition] = useState({ latitude: null, longitude: null, altitude: null });
    const [vehicleStatus, setVehicleStatus] = useState("");

    const DEPARTURE_TIME_FOR_ID_8 = "November 12, 2024, 08:00"; 
    const [taskModes, setTaskModes] = useState({});

    const handleDelete = async (taskId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/delivery/deliveries/${taskId}`, {
            withCredentials: true,
            headers: {
                'ngrok-skip-browser-warning': true,
            }
            }).then(() => {
                fetchTasks();
            }).catch((error) => {
                console.error(error);
            })
    };

    useEffect(() => {
        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}`
        });

        ros.on('error', (error) => {
            console.error("ROS connection error:", error);
        });

        ros.on('connection', () => {
            console.log("Connected to ROS");
        });

        ros.on('close', () => {
            console.log("ROS connection closed");
        });

        const locationTopic = new ROSLIB.Topic({
            ros: ros,
            name: "/webtopic",
            messageType: "std_msgs/String"
        });

        locationTopic.subscribe((message) => {
            const data = JSON.parse(message.data);
            const { position, speed, armed, network_mode, rssi } = data;
            setVehiclePosition(position);
            setVehicleStatus({ speed, armed, network_mode, rssi });
        });

        return () => {
            locationTopic.unsubscribe();
            ros.close();
        };
    }, []);

    const fetchTasks = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/delivery/deliveries`, {
                headers: {
                    'ngrok-skip-browser-warning': true,
                }
            });

            if (response.data.length === 0) {
                setEmpty(true);
            }
            
            const updatedTasks = response.data.map(task => {
                if (task.id === 8) {
                    return { ...task, status: 0, departed: DEPARTURE_TIME_FOR_ID_8 };
                }
                return task;
            });
            setTasks(updatedTasks);

            const initialModes = {};
            updatedTasks.forEach(task => {
                initialModes[task.id] = "AUTO";
            });
            setTaskModes(initialModes);

            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, []);    

    const isLocationMatch = (pos1, pos2) => {
        const tolerance = 0.0001; 
        return (
            Math.abs(pos1.latitude - pos2.latitude) < tolerance &&
            Math.abs(pos1.longitude - pos2.longitude) < tolerance
        );
    };

    const handleWaypointInfoClick = (task) => {
        setSelectedTask(task);
        setInfo(true);
    };

    const handleEditClick = (task) => {
        setSelectedEditTask(task);
        setEditPop(true);
    };

    const closeModal = () => setInfo(false);
    const closeEditModal = () => setEditPop(false);
    const closeLaunchModal = () => setLaunchPop(false);

    const handleModeToggle = (taskId) => {
        setTaskModes((prevModes) => ({
            ...prevModes,
            [taskId]: prevModes[taskId] === "AUTO" ? "MANUAL" : "AUTO",
        }));
    };

    // Updated handleLaunch function
    const handleLaunch = (taskId, coordinate) => {
        console.log(`Launching task ${taskId}`);
        console.log(`Destination coordinate: ${coordinate.latitude}, ${coordinate.longitude}, ${coordinate.altitude}`);

        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}`
        });

        ros.on('connection', () => {
            console.log("Connected to ROS for launching task");

            const locationDummy = {
                frame: 3,                 // Global (relative altitude)
                command: 22,              // Command for NAV_WAYPOINT
                is_current: false,        // False for all waypoints except the first one
                autocontinue: true,       // Automatically continue to the next waypoint
                param1: 0,                // Hold time
                param2: 0,                // Acceptance radius
                param3: 0,                // Pass through (set to 0 for direct flight)
                param4: 0,                // Desired yaw angle -6.398279012231381, 106.80901167313652
                z_alt: 4.0               // Altitude in meters
            };
            
            const locationTakeOff = {
                frame: 3,                 // Global (relative altitude)
                command: 22,              // Command for NAV_WAYPOINT
                is_current: true,        // False for all waypoints except the first one
                autocontinue: true,       // Automatically continue to the next waypoint
                param1: 0,                // Hold time
                param2: 0,                // Acceptance radius
                param3: 0,                // Pass through (set to 0 for direct flight)
                param4: 0,                // Desired yaw angle -6.398279012231381, 106.80901167313652
                z_alt: 10.0               // Altitude in meters
            };

            const locationDestination = {
                frame: 3,                 // Global (relative altitude)
                command: 16,              // Command for NAV_WAYPOINT
                is_current: false,        // False for all waypoints except the first one
                autocontinue: true,       // Automatically continue to the next waypoint
                param1: 0,                // Hold time
                param2: 0,                // Acceptance radius
                param3: 0,                // Pass through (set to 0 for direct flight)
                param4: 0,                // Desired yaw angle -6.398279012231381, 106.80901167313652
                x_lat: coordinate.latitude, // Latitude
                y_long: coordinate.longitude, // Longitude
                z_alt: 10.0               // Altitude in meters
            };

            const locationPrecision = {
                frame: 3,                 // Global (relative altitude)
                command: 16,              // Command for NAV_WAYPOINT
                is_current: false,        // False for all waypoints except the first one
                autocontinue: true,       // Automatically continue to the next waypoint
                param1: 5,                // Hold time
                param2: 0,                // Acceptance radius
                param3: 0,                // Pass through (set to 0 for direct flight)
                param4: 0,                // Desired yaw angle -6.398279012231381, 106.80901167313652
                x_lat: coordinate.latitude, // Latitude
                y_long: coordinate.longitude, // Longitude
                z_alt: 5.0               // Altitude in meters
            };

            const locationLand = {
                frame: 3,                 // Global (relative altitude)
                command: 21,              // Command for NAV_WAYPOINT
                is_current: false,        // False for all waypoints except the first one
                autocontinue: true,       // Automatically continue to the next waypoint
                param1: 0,                // Hold time
                param2: 0,                // Acceptance radius
                param3: 0,                // Pass through (set to 0 for direct flight)
                param4: 0,                // Desired yaw angle -6.3983240321412325, 106.
                z_alt: 0             // Altitude in meters
            };

            const waypointPushTopic = new ROSLIB.Service({
                ros: ros,
                name: '/mavros/mission/push',
                serviceType: 'mavros_msgs/WaypointPush'
            });
            
            const deliveryTopic = new ROSLIB.Topic({
                ros: ros,
                name: "/web/trigger_start",
                messageType: "std_msgs/String"
            });
            
            const waypointMessage = new ROSLIB.ServiceRequest({
                start_index: 0,  // Start from the first waypoint
                waypoints: [locationDummy, locationTakeOff, locationDestination, locationPrecision, locationLand]  // Array of waypoints to send
            });

            const message = new ROSLIB.Message({
                data: "start"  // Message content to send
            });

            // We push the waypointDummy first, then the actual waypoints so that when error occured on the second waypoint, we revert to the first one.
            waypointPushTopic.callService(waypointMessage, (response) => {
                if (response.success) {
                    console.log('Sent "waypoint" message to /mavros/mission/push');
                } else {
                    console.warn('Failed to push waypoints.');
                }
            }, (error) => {
                console.error('Service call failed:', error);
            });

            deliveryTopic.publish(message);
            console.log('Sent "start" message to /dummy-gps/trigger');
        });

        ros.on('close', () => {
            console.log("ROS connection closed after launching task");
        });
    };

    const handleAbort = (taskId) => {
        console.log(`Aborting task ${taskId} and returning to launch`);
    
        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}`
        });
    
        ros.on('connection', () => {
            console.log("Connected to ROS for abort task");
    
            // Define the RTL (Return to Launch) message
            const rtlCommand = {
                frame: 3,                // Global (relative altitude)
                command: 20,             // MAV_CMD_NAV_RETURN_TO_LAUNCH
                is_current: true,        // This is the current command
                autocontinue: true,      // Automatically execute
                param1: 0,               // No additional parameters
                param2: 0,
                param3: 0,
                param4: 0
            };
    
            const rtlService = new ROSLIB.Service({
                ros: ros,
                name: '/mavros/cmd/command', 
                serviceType: 'mavros_msgs/CommandLong'
            });
    
            const rtlServiceRequest = new ROSLIB.ServiceRequest({
                command: rtlCommand.command, // Command for RTL
                confirmation: 0,
                param1: rtlCommand.param1,
                param2: rtlCommand.param2,
                param3: rtlCommand.param3,
                param4: rtlCommand.param4
            });
    
            // Call the RTL service
            rtlService.callService(rtlServiceRequest, (response) => {
                if (response.success) {
                    console.log(`Task ${taskId} successfully aborted. Vehicle returning to launch.`);
                } else {
                    console.warn(`Failed to abort task ${taskId}.`);
                }
            }, (error) => {
                console.error('RTL command failed:', error);
            });
        });
    
        ros.on('close', () => {
            console.log("ROS connection closed after abort command");
        });
    };

    return (
        <div className="listTaskDelivery">
            <table className="tabelDekstopDelivery">
                <thead>
                    <tr>
                        <th className="idList">ID</th>
                        <th className="nameList">Waypoint</th>
                        <th className="taskProses">Status</th>
                        <th className="createAt">Depart Time</th>
                        <th className="packageDescription">Content</th>
                        <th className="modeColumn">Mode</th>
                        <th className="actionButton">Settings</th>
                    </tr>
                </thead>
                <tbody>
                    {loadingList ? (
                        <div className="loaderLogin listLoader"></div>
                    ) : emptyCheck ? (
                        <tr>
                            <td className="emptyTeks" colSpan={8}>
                                <h2>No Data Available</h2>
                            </td>
                        </tr>
                    ) : (
                        tasks
                            .sort((a, b) => new Date(b.departed) - new Date(a.departed))
                            .map((task) => (
                                <tr key={task.id}>
                                    <td className="idList">{task.id}</td>
                                    <td className="nameList">{task.name}</td>
                                    <td className={`taskProses ${task.status}`}>
                                        <h5>{task.status}</h5>
                                    </td>
                                    <td className="packageList">{task.departed.toString()}</td> 
                                    <td className="createAt">{task.description}</td>
                                    <td className="modeColumn">
                                        <button 
                                            className={`modeButton ${taskModes[task.id].toLowerCase()}`} 
                                            onClick={() => handleModeToggle(task.id)}
                                        >
                                            {taskModes[task.id]}
                                        </button>
                                    </td>

                                    <td className="actionButton">
                                        <div className="dropdown">
                                            <button className="ellipsisButton">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </button>
                                            <div className="dropdown-content">
                                                <button onClick={() => handleWaypointInfoClick(task)}>
                                                    <FontAwesomeIcon icon={faInfoCircle} /> Delivery Info
                                                </button>
                                                <button onClick={() => handleEditClick(task)}>
                                                    <FontAwesomeIcon icon={faPencil} /> Edit
                                                </button>
                                                <button onClick={() => console.log(handleDelete(task.id))}>
                                                    <FontAwesomeIcon icon={faEraser} /> Delete
                                                </button>
                                                {taskModes[task.id] === "MANUAL" && (
                                                    <>
                                                        <button className="launchButton" onClick={() => {
                                                            // setSelectedTask(task)
                                                            // setLaunchConfirmPop(true)
                                                            handleLaunch(task.id, task.destination.endLatLongAlt);
                                                        }}>
                                                            <FontAwesomeIcon icon={faRocket} /> Launch
                                                        </button>
                                                        <button className="abortButton" onClick={() => handleAbort(task.id)}>
                                                            <FontAwesomeIcon icon={faTimes} /> Abort
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                    )}
                </tbody>
            </table>
            {infoPop && (
                <InfoTask onClose={closeModal} task={selectedTask} />
            )}
            {editPop && (
                <EditTask onClose={closeEditModal} task={selectedEditTask} />
            )}
            {launchConfirmPop && (
                <LaunchConfirmation 
                    onConfirm={() => handleLaunch(selectedTask.id, selectedTask.destination.endLatLongAlt)} 
                    onClose={closeLaunchModal} 
                />
            )}
        </div>
    );
};