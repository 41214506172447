import "../style/current.css";
import "../style/listTask.css";
import axios from "axios";
import { useEffect, useState } from "react";
import React from 'react';
import ROSLIB from "roslib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FinishProcess } from "./current/finish.js";
import { CancelProcess } from "./current/cancel.js";
import baseUrl from '../config.js';

export const CurrentMission = () => {
    const [tasksActive, setTasksActive] = useState([]);
    const [waypoint, setWaypoint] = useState([]);
    const [vehiclePosition, setVehiclePosition] = useState({ latitude: null, longitude: null, altitude: null });
    const [vehicleStatus, setVehicleStatus] = useState("");
    const [refreshList, setRefresh] = useState(false);
    const [cancelPop, setCancel] = useState(false);
    const [finishPop, setFinish] = useState(false);
    const [dataUp, setData] = useState([]);
    const [loadingList, setLoading] = useState(true);
    const [emptyCheck, setEmpty] = useState(false);

    const axiosJWT = axios.create();

    const handleRefreshnya = () => {
        setRefresh(current => !current);
    };

    // Fetch the tasks (active missions)
    const allTask = async () => {
        try {
            const response = await axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/v1/delivery/deliveries`, {
                withCredentials: true,
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                }
            });
            const filteredTasks = response.data.filter(task => 
                task.status === "onflight" || task.status === "source" || task.status === "destination"
            );  
            if (filteredTasks.length === 0) {
                setEmpty(true); // Set emptyCheck to true if no tasks are found
            }
            setLoading(false);
            setTasksActive(filteredTasks);
        } catch (error) {
            console.error(error);
        }
    };

    // Initialize ROS connection for vehicle position updates
    useEffect(() => {
        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}`
        });

        ros.on('error', (error) => {
            console.error("ROS connection error:", error);
        });

        ros.on('connection', () => {
            console.log("Connected to ROS");
        });

        ros.on('close', () => {
            console.log("ROS connection closed");
        });

        const locationTopic = new ROSLIB.Topic({
            ros: ros,
            name: "/webtopic",
            messageType: "std_msgs/String"
        });

        locationTopic.subscribe((message) => {
            const data = JSON.parse(message.data);
            const { position, speed, armed, network_mode, rssi } = data;
            setVehiclePosition(position);
            setVehicleStatus({ speed, armed, network_mode, rssi });
        });

        return () => {
            locationTopic.unsubscribe();
            ros.close();
        };
    }, []);


    const showCancel = (data) => {
        setCancel(current => !current);
        setData(data);
    };

    const showFinish = (data) => {
        setFinish(current => !current);
        setData(data);
    };

    useEffect(() => {
        allTask();
    }, [refreshList]);

    return (
        <div className="currentMission">
            <h3>Current Delivery</h3>

            <table className="currentTable">
                <thead className="titleDekstop">
                    <tr>
                        <th className="idListCurrent">ID</th>
                        <th className="nameListCurrent">Source</th>
                        <th className="nameListCurrent">Destination</th>
                        <th className="taskProsesCurrent">Location</th>
                        <th className="launchAt">Depart Time</th>
                        <th className="taskProsesCurrent">Content</th>
                        <th className="actionButtonCurrent">Settings</th>
                    </tr>
                </thead>
                <tbody className="tabelDekstop">
                    {loadingList ? (
                        <div className="loaderLogin listLoader"></div>
                    ) : emptyCheck ? (
                        <tr>
                            <td className="emptyTeks" colSpan={7}>
                                <h2>No Data Available</h2>
                            </td>
                        </tr>
                    ) : (
                        tasksActive.map((task) => (
                            <tr key={task.id}>
                                <td className="idListCurrent">{task.id}</td>
                                <td className="nameListCurrent">{task.source.name}</td>
                                <td className="nameListCurrent">{task.destination.name}</td>
                                <td className={`taskProsesCurrent ${task.status}`}>
                                    <h5>{task.status}</h5>
                                </td>
                                <td className="launchedAt">November 12, 2024, 08:00</td>
                                <td className="packageCurr">{task.description}</td>
                                <td className="actionButtonCurrent">
                                    {task.status === 0 ? (
                                        <button className="cancelTaskButtonCurrent" onClick={() => showCancel(task)}>
                                            <FontAwesomeIcon icon={faXmark} className="iconX" /> <h6>Abort Mission</h6>
                                        </button>
                                    ) : (
                                        <div className="disabledCancel">
                                            <h2>---</h2>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};
