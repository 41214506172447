import React, { useState } from "react";
import "../style/location.css";
import MapMoving from "./mapMoving";

export const Location = () => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <div className="droneLocation">
            <div className="viewDrone">
                <h3>Drone View</h3>
                <div className="iframe-wrapper">
                    {isVisible ? (
                        <iframe 
                            className="responsive-iframe"
                            src={process.env.REACT_APP_RTC_URL}
                            frameBorder="0"
                            allowFullScreen
                        />
                    ) : (
                        <div className="hidden">
                            <i className="bi bi-eye-slash-fill icon"></i>
                        </div>
                    )}
                </div>
            </div>

		
            <div className="viewMap">
                <div className="titleMapDrone">
                    <h3>Drone Location</h3>
                    <h4 className="curr_alt">Alt : 0 m</h4>
                </div>

                <div className="viewLocation">
                    <MapMoving initialZoom={18} />
                </div>
	    </div>
        </div>
    );
};