import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../style/map.css';
import useDroneStore from '../store/droneStore'; // Import the Zustand store

mapboxgl.accessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHJ5NzVxaWwxbWhoMnJyejM4aTNlemM0In0.GLuB4QgKV5eMCKoq5PsPBw';

const MapMoving = ({ initialZoom = 18, center = [107.5716212, -7.0136531] }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null); // Store map reference
  const altitudeRef = useRef(3000); // Default altitude for the camera
  const coordinates = useDroneStore((state) => state.coordinates); // Get coordinates from the store
  const prevCoordinatesRef = useRef([null, null]); // Store previous coordinates to compare

  // Initialize map only once (on component mount)
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: center,
      zoom: initialZoom,
    });

    // Add navigation control
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    mapRef.current = map;

    mapRef.current.on('style.load', () => {
      mapRef.current.setFog({});
    });

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        console.log('Map instance removed');
      }
    };
  }, []); // Run only once when component mounts or initialZoom/center change

  // React to coordinate changes
  useEffect(() => {
    if (!coordinates || !mapRef.current) return;

    const [latitude, longitude] = coordinates;

    // Compare the new coordinates with the previous ones
    if (latitude === prevCoordinatesRef.current[0] && longitude === prevCoordinatesRef.current[1]) {
      return; // Skip update if coordinates are the same
    }

    // Update camera position
    const camera = mapRef.current.getFreeCameraOptions();
    mapRef.current.setFreeCameraOptions(camera);

    // Add or update the marker
    new mapboxgl.Marker({ color: 'red' })
      .setLngLat([longitude, latitude])
      .addTo(mapRef.current);

    // Update the previous coordinates ref
    prevCoordinatesRef.current = [latitude, longitude];

    console.log('Marker added and camera updated:', { latitude, longitude });
  }, [coordinates?.join()]); // Use `coordinates?.join()` as the dependency to track changes in the coordinates array

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
    </div>
  );
};

export default MapMoving;

// OLD VERSION
// import React, { useRef, useEffect } from 'react';
// import mapboxgl from 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import '../style/map.css';
// import useDroneStore from '../store/droneStore'; // Import the Zustand store

// mapboxgl.accessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHJ5NzVxaWwxbWhoMnJyejM4aTNlemM0In0.GLuB4QgKV5eMCKoq5PsPBw';

// const MapMoving = ({ initialZoom = 18, center = [106.8092152, -6.3981919] }) => {
//   const mapContainerRef = useRef(null);
//   const mapRef = useRef(null); // Store map reference
//   const altitudeRef = useRef(3000); // Default altitude for the camera
//   const coordinates = useDroneStore((state) => state.coordinates); // Get coordinates from the store

//   useEffect(() => {
//     // Initialize the map
//     const map = new mapboxgl.Map({
//       container: mapContainerRef.current,
//       style: 'mapbox://styles/mapbox/satellite-v9',
//       center: center,
//       zoom: initialZoom,
//     });

//     // Add navigation control
//     map.addControl(new mapboxgl.NavigationControl(), 'top-right');
//     mapRef.current = map;

//     mapRef.current.on('style.load', () => {
//       mapRef.current.setFog({});
//     });

//     // Clean up on unmount
//     return () => {
//       // Remove the map instance
//       if (mapRef.current) {
//         mapRef.current.remove();
//         console.log('Map instance removed');
//       }
//     };
//   }, [initialZoom, center]);

//   useEffect(() => {
//     if (!coordinates || !mapRef.current) return;

//     const [latitude, longitude] = coordinates;

//     // Update camera
//     const camera = mapRef.current.getFreeCameraOptions();
//     camera.position = mapboxgl.MercatorCoordinate.fromLngLat([longitude, latitude], altitudeRef.current);
//     camera.lookAtPoint([longitude, latitude]);
//     mapRef.current.setFreeCameraOptions(camera);

//     // Add a marker at the GPS location
//     new mapboxgl.Marker({ color: 'red' })
//       .setLngLat([longitude, latitude])
//       .addTo(mapRef.current);

//     console.log('Marker added and camera updated:', { latitude, longitude });
//   }, [coordinates]); // React to changes in the coordinates

//   return (
//     <div>
//       <div className="map-container" ref={mapContainerRef} />
//     </div>
//   );
// };

// export default MapMoving;

