import axios from "axios";
import { useEffect, useState } from "react";
import "../../../style/addInput.css";
import React from "react";

export const AddManual = ({ handleClick, handleClose }) => {
    const [waypoints, setWaypoints] = useState([]);
    const [token, setToken] = useState("");
    const [waypointVal, setWaypointVal] = useState("");
    const [departTime, setDepartTime] = useState("");
    const [content, setContent] = useState("");

    const fetchWaypoints = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/waypoint/waypoints`, {
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                }
            });
            setWaypoints(response.data.data); // Assume `data` contains the array
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddDelivery = async (e) => {
        e.preventDefault();
        if (!departTime) {
            alert("Please select a departure time.");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/delivery/deliveries`, {
                waypoint_id: waypointVal,
                depart_time: new Date(departTime).toISOString(),
                content: content,
                status: "departed",
                mode: "auto",
            });

            if (response.status === 201) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error.response?.data || error.message);
        }
    };

    useEffect(() => {
        fetchWaypoints();
    });

    return (
        <div className="addManual">
            <form onSubmit={handleAddDelivery}>
                <table className="tabelAddManual">
                    <tbody>
                        <tr>
                            <td>
                                <h2 className="labelField">1. Waypoint Target</h2>
                                <select
                                    value={waypointVal}
                                    onChange={(e) => setWaypointVal(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Select your waypoint
                                    </option>
                                    {waypoints.map((waypoint) => (
                                        <option key={waypoint.id} value={waypoint.id}>
                                            {waypoint.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h2 className="labelField">2. Package Description</h2>
                                <input
                                    type="text"
                                    placeholder="What is the package?"
                                    required
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr className="custom-row">
                            <td className="custom-td">
                                <h2 className="labelField">3. Input Depart Time</h2>
                                <input
                                    type="datetime-local"
                                    required
                                    value={departTime}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    className="inputField"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="buttonSection">
                                <input type="submit" value="Submit" />
                                <button
                                    type="button"
                                    className="cancelButton"
                                    onClick={handleClick}
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
};