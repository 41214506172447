// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialogContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.waypointsTable {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.waypointsTable th, .waypointsTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.dialogActions button {
    margin: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dialogActions .confButton {
    background: green;
    color: white;
}

.dialogActions .cancButton {
    background: red;
    color: white;
}

.confButton:disabled {
    background-color: #ccc; /* Light grey color */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.errorMessage {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/launchConfirm.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,sBAAsB,EAAE,qBAAqB;IAC7C,mBAAmB,EAAE,6CAA6C;AACtE;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".dialogOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.dialogContent {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    width: 400px;\n    text-align: center;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.waypointsTable {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 10px 0;\n}\n\n.waypointsTable th, .waypointsTable td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\n.dialogActions button {\n    margin: 10px;\n    padding: 8px 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.dialogActions .confButton {\n    background: green;\n    color: white;\n}\n\n.dialogActions .cancButton {\n    background: red;\n    color: white;\n}\n\n.confButton:disabled {\n    background-color: #ccc; /* Light grey color */\n    cursor: not-allowed; /* Change cursor to indicate disabled state */\n}\n\n.errorMessage {\n    color: red;\n    font-size: 0.9rem;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
