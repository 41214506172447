import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import "../../style/infoTask.css";

export const InfoTask = ({ waypoint, handleClose }) => {
  if (!waypoint) return null;

  return (
    <div className="modal-overlay"> {/* Modal background */}
      <div className="modal-container"> {/* Modal content */}
        <button onClick={handleClose} className="modal-close-btn">
          <FontAwesomeIcon icon={faXmark} className="modal-close-icon" />
        </button>
        <div className="modal-content">
          <h3>Delivery Information</h3>
          <div className="info-block">
            <div className="info-card"><span>ID:</span></div>
            <div className="info-card value-card"><span>{waypoint.id}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Name:</span></div>
            <div className="info-card value-card"><span>{waypoint.name}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Source:</span></div>
            <div className="info-card value-card"><span>{waypoint.source.name}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Source Coordinates:</span></div>
            <div className="info-card value-card">
              <span>Latitude: {waypoint.source.startLatLongAlt.latitude}, Longitude: {waypoint.source.startLatLongAlt.longitude}, Altitude: {waypoint.source.startLatLongAlt.altitude}</span>
            </div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Destination:</span></div>
            <div className="info-card value-card"><span>{waypoint.destination.name}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Destination Coordinates:</span></div>
            <div className="info-card value-card">
              <span>Latitude: {waypoint.destination.endLatLongAlt.latitude}, Longitude: {waypoint.destination.endLatLongAlt.longitude}, Altitude: {waypoint.destination.endLatLongAlt.altitude}</span>
            </div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Status:</span></div>
            <div className="info-card value-card"><span>{waypoint.status === 1 ? "Scheduled" : waypoint.status === 2 ? "Arrived" : "Departed"}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Departed:</span></div>
            <div className="info-card value-card"><span>{waypoint.departed}</span></div>
          </div>
          <div className="info-block">
            <div className="info-card"><span>Description:</span></div>
            <div className="info-card value-card"><span>{waypoint.description}</span></div>
          </div>
        </div>
      </div>
    </div>
  );
};
