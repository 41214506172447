import "../../style/addtask.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import alert from "../../assets/alert.png";
import axios from "axios";
import { useState } from "react";
import React from 'react';
import baseUrl from '../../config.js';

export const DeleteWay = ({ handleClick, handleConfirm}) =>{

    const [status, setStatus] = useState();
    
    return(
        <div className="addTask">
            <div className="formDelete">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark}  className="logoExit"/>
                </button>
                <div className="mainContent-delete">
                    <img src={alert} alt="" />
                    <h1>Are you sure want to <br/> delete this data?</h1>

                    <div className="buttonChoose">
                        <button className="yes" onClick={handleConfirm}>Yes</button>
                        <button className="no" onClick={handleClick}>No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}