// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  width: 100%; /* Adjust the width as needed */
  height: 45vh;
}

.mapboxgl-canvas {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/style/map.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,+BAA+B;EAC5C,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,WAAW;EACX,YAAY;AACd","sourcesContent":[".map-container {\n  width: 100%; /* Adjust the width as needed */\n  height: 45vh;\n}\n\n.mapboxgl-canvas {\n  position: relative;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
