import React, { useEffect, useRef } from 'react';
import io from 'socket.io-client';

export const VideoFeed = () => {
  const videoRef = useRef(null);
  const pc = useRef(null);
  const socket = useRef(null);

  useEffect(() => {
    // Establish WebSocket connection
    socket.current = io('wss://f422-103-3-220-220.ngrok-free.app');

    // Create WebRTC peer connection
    pc.current = new RTCPeerConnection();

    // Add event listener for remote streams
    pc.current.ontrack = (event) => {
      if (videoRef.current) {
        videoRef.current.srcObject = event.streams[0];
      }
    };

    // Exchange ICE candidates
    pc.current.onicecandidate = (event) => {
      if (event.candidate) {
        socket.current.emit('ice_candidate', JSON.stringify({ candidate: event.candidate }));
      }
    };

    // Handle the signaling process
    const createOffer = async () => {
      const offer = await pc.current.createOffer();
      await pc.current.setLocalDescription(offer);
      socket.current.emit('webrtc_offer', JSON.stringify({ offer: pc.current.localDescription }));
    };

    // Listen for WebRTC answer from server
    socket.current.on('webrtc_answer', async (data) => {
      const answer = JSON.parse(data).answer;
      await pc.current.setRemoteDescription(new RTCSessionDescription(answer));
    });

    // Start WebRTC signaling
    createOffer();

    return () => {
      // Clean up WebSocket and peer connection on component unmount
      socket.current.disconnect();
      pc.current.close();
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} autoPlay playsInline />
    </div>
  );
};
