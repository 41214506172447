import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import "../style/waypoints.css";
import React from 'react';
import { Sidebar } from "../components/sidebar";
import { WaypointList } from "../components/waypointList";
import { AddWaypoints } from "../components/waypoints/addWaypoints";
import baseUrl from '../config.js';
import Map from "../components/map.js";

export const Waypoints = () =>{

    const [activeInfoWindow, setActiveInfoWindow] = useState("");
    const [token, setToken] = useState('');
    const [center, setCenter] = useState();
    const [refreshCheck, setRefresh] = useState(false);
    const [isLoggedIn, setLogged] = useState(false);
    const [addWay, setAdd] = useState(false);
    const [waypoints, setWaypoints] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [loadingList, setLoading] = useState(true);

    // check user session function
    const checkSession = () =>{
        const sessionStatus = sessionStorage.getItem("userData");

        if(sessionStatus!==''){
            setLogged(true);
        }
        else{
            window.location.replace('/');
        }
    }

    // refresh data on change
    const handleRefresh = () =>{
        setRefresh(current=>!current);
    }

    const showAddWay = () =>{
        setAdd(current=>!current);
    }

    // get refresh token
    const refreshToken = async() => {
        try {
            // const response = await axios.get(`${baseUrl}/token`);
            // setToken(response.data.accessToken);
            // setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const axiosJWT = axios.create();

    // get all waypoint data from table
    const allWaypoint = async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/waypoint/waypoints`, {
                withCredentials: true,
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                }
            });
            setLoading(false);
            setWaypoints(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    // get coordinate based on latitude and longitude value
    const containerStyle = {
        width: "100%",
        height: "100%",
    }

    // get latitude and longitude value from user click
    const mapClicked = (event) => { 
        console.log(event.latLng.lat(), event.latLng.lng()) 
    }

    // set marker on click
    const markerClicked = (marker, index) => {  
        setActiveInfoWindow(index)
        console.log(marker, index) 
    }

    const markerDragEnd = (event, index) => { 
        console.log(event.latLng.lat())
        console.log(event.latLng.lng())
    }
    
    useEffect(() =>{
        refreshToken();
        allWaypoint();
    }, [refreshCheck]);

    useEffect(() =>{
        checkSession();
    }, []);

    return(
        <div className="waypointsMenu">
            {isLoggedIn &&
                <div>
                    <Sidebar></Sidebar>

                    <div className="waypointsContent">

                    <div className="topWaypoints">
                        <div className="titleWaypoints">
                            <h1>Waypoints</h1>
                        </div>
                    </div>
{/* 
                    <div className="mapWaypoints">

                        <div className="mapView">
                            <Map />
                        </div>                
                    </div> */}

                    <div className="listData">
                        <div className="listTitleWaypoint">
                            <h3>Waypoint List</h3>
                            <button className="buttonAddWay" onClick={showAddWay}>
                                <FontAwesomeIcon icon={faPlus}  className="logoAddWay"/>
                                <h4>Add New Waypoints</h4>
                            </button>
                        </div>
                        <WaypointList waypoints={waypoints} handleRefresh={handleRefresh} loadingNow={loadingList}></WaypointList>
                    </div>
                    </div>
                </div>
            }
             

             {addWay && <AddWaypoints handleClick={showAddWay} handleRefresh={handleRefresh}></AddWaypoints>}
        </div>
    )
}
