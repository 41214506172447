import React, { useEffect, useState } from "react";
import ROSLIB from "roslib"; // Import roslib correctly
import refresh from "../assets/refresh.png";
import "../style/status.css";

export const Status = () => {
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [armed, setArmed] = useState(false);
    const [status, setStatus] = useState("Onboard");
    const [connectionClass, setConnectionClass] = useState("boxInternetInactive");
    const [networkMode, setNetworkMode] = useState("4G");
    const [rssi, setRssi] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [speed, setSpeed] = useState(null);

    useEffect(() => {
        // Initialize Ros connection
        const ros = new ROSLIB.Ros({
            url: `${process.env.REACT_APP_ROS_URL}/` // Use WebSocket URL
        });

        // Handle connection events
        ros.on('connection', () => {
            console.log('Connected to WebSocket server.');
            setConnected(true);
            setConnectionClass("boxInternetActive");
            setLoading(true);
        });

        ros.on('error', (error) => {
            console.error("Error connecting to WebSocket:", error);
            setConnected(false);
            setConnectionClass("boxInternetInactive");
            setLoading(false);
        });

        ros.on('close', () => {
            console.log('Connection closed.');
            setConnected(false);
            setConnectionClass("boxInternetInactive");
            setLoading(false);
        });

        // Create a topic to subscribe to /webtopic
        const mavrosStateTopic = new ROSLIB.Topic({
            ros: ros,
            name: '/webtopic',
            messageType: 'std_msgs/String'
        });

        // Subscribe to the topic
        mavrosStateTopic.subscribe((message) => {
            // Parse the incoming message data
            const parsedMessage = JSON.parse(message.data);
            console.log("Received message:", parsedMessage);
            console.log(message.data);

            const { position, speed, armed, network_mode, rssi } = parsedMessage;

            // Update the state with the parsed data
            setArmed(armed);
            setStatus(armed ? "Onflight" : "Onboard");
            setSpeed(speed);

            // Set network mode (convert "FDD LTE" to "4G")
            if (network_mode === "FDD LTE") {
                setNetworkMode("4G");
            } else {
                setNetworkMode(network_mode); // Keep original value if not FDD LTE
            }

            // Update the RSSI value (signal strength)
            setRssi(rssi);
        });

        // Clean up on component unmount
        return () => {
            mavrosStateTopic.unsubscribe();
            ros.close();
        };
    }, []);

    return (
        <div className="statusConn">
            <div className="botSection">
                <div className="botStatus">
                    <div className="boxStatusCircle">
                        <h3>Connectivity</h3>
                        <div className={connectionClass}></div>
                    </div>

                    {/* Render GSM and Strength */}
                    <div className="boxStatus">
                        <h3>GSM</h3>
                        <div className="box">
                            <h4>{connected ? networkMode : "N/A"}</h4>
                        </div>
                    </div>
                    <div className="boxStatus">
                        <h3>Strength</h3>
                        <div className="box-bar">
                            {connected ? (
                                <>
                                    {/* Signal strength representation based on RSSI */}
                                    <div className="bar-1-active-four"></div>
                                    <div className="bar-2-active-four"></div>
                                    <div className="bar-3-active-four"></div>
                                    <div className="bar-4-active-four"></div>
                                </>
                            ) : (
                                <h4>N/A</h4>
                            )}
                        </div>
                    </div>

                    <div className="boxStatus">
                        <h3>Status</h3>
                        <div className="boxPosition">
                            <h4>{status}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
