import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import "../style/waypoints.css";
import React from 'react';
import { Sidebar } from "../components/sidebar";
import { LocationList } from "../components/locationList";
import { AddLocations } from "../components/locations/addLocations";
import baseUrl from '../config.js';
import Map from "../components/map.js";

export const Locations = () => {
    const [activeInfoWindow, setActiveInfoWindow] = useState("");
    const [token, setToken] = useState('');
    const [center, setCenter] = useState([106.83272454782166, -6.226968644428446]); // Default center coordinates
    const [refreshCheck, setRefresh] = useState(false);
    const [isLoggedIn, setLogged] = useState(false);
    const [addWay, setAdd] = useState(false);
    const [waypoints, setWaypoints] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [loadingList, setLoading] = useState(true);
    const [isMarkersReady, setMarkersReady] = useState(false); // New state to track markers readiness

    // check user session function
    const checkSession = () => {
        const sessionStatus = sessionStorage.getItem("userData");
        if (sessionStatus !== '') {
            setLogged(true);
        } else {
            window.location.replace('/');
        }
    }

    // refresh data on change
    const handleRefresh = () => {
        setRefresh(current => !current);
    }

    const showAddWay = () => {
        setAdd(current => !current);
    }

    // get all waypoint data from table
    const allWaypoint = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/landing/landing_points`, {
                withCredentials: true,
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                }
            });
            setLoading(false);
            setWaypoints(response.data.data);
            getCoordinate(response.data.data); // Update markers when waypoints are fetched
        } catch (error) {
            console.log(error);
        }
    }

    // get coordinate based on latitude and longitude value
    const getCoordinate = (data) => {
        const initialMarkers = data.map((item) => ({
            coordinates: [item.longitude, item.latitude] // [lng, lat] order
        }));
        setMarkers(initialMarkers);
        if (initialMarkers.length > 0) {
            setCenter(initialMarkers[0].coordinates); // Center the map on the first marker
        }
        setMarkersReady(true); // Indicate that the markers are ready
    }

    useEffect(() => {
        allWaypoint();
    }, [refreshCheck]);

    useEffect(() => {
        checkSession();
    }, []);

    return (
        <div className="waypointsMenu">
            {isLoggedIn &&
                <div>
                    <Sidebar></Sidebar>

                    <div className="waypointsContent">
                        <div className="topWaypoints">
                            <div className="titleWaypoints">
                                <h1>Landing Points</h1>
                            </div>
                        </div>

                        <div className="mapWaypoints">
                            <div className="mapView">
                                {/* Only render Map when markers are ready */}
                                {isMarkersReady && (
                                    <Map markers={markers} initialZoom={10} center={center} />
                                    
                                )}
                            </div>
                        </div>

                        <div className="listData">
                            <div className="listTitleWaypoint">
                                <h3>Landing Points List</h3>
                                <button className="buttonAddWay" onClick={showAddWay}>
                                    <FontAwesomeIcon icon={faPlus} className="logoAddWay" />
                                    <h4>Add New Landing Points</h4>
                                </button>
                            </div>
                            <LocationList waypoints={waypoints} handleRefresh={handleRefresh} loadingNow={loadingList}></LocationList>
                        </div>
                    </div>
                </div>
            }
            {addWay && <AddLocations handleClick={showAddWay} handleRefresh={handleRefresh}></AddLocations>}
        </div>
    )
}