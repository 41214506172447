import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../style/map.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHJ5NzVxaWwxbWhoMnJyejM4aTNlemM0In0.GLuB4QgKV5eMCKoq5PsPBw';

const Map = ({ markers = [], initialZoom = 15, center = [106.83272454782166, -6.226968644428446] }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(initialZoom); // Adjusted zoom level for focus

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: center,
      zoom: zoom
    });

    mapRef.current = map;

    mapRef.current.on('style.load', () => {
      mapRef.current.setFog({});
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Add a marker at the center
    // Add markers from props
    markers.forEach((marker) => {
      new mapboxgl.Marker()
        .setLngLat(marker.coordinates)
        .addTo(map);
    });

    map.on('move', () => {
      setZoom(map.getZoom().toFixed(2));
    });

    // Clean up on unmount
    return () => {
      map.remove();
    };
  }, [zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className='map-container' ref={mapContainerRef} />
    </div>
  );
}

export default Map;
