import React, { useEffect, useState } from "react";
import "../../style/launchConfirm.css"; // Add your dialog styles here
import ROSLIB from "roslib";
import MapClickable from "../mapClickable"; // Import the MapClickable component
import useDroneStore from "../../store/droneStore"; // Import the store hook

export const LaunchConfirmation = ({ onConfirm, onClose }) => {
    const [waypoints, setWaypoints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('Loading waypoints...');
    const [connected, setConnected] = useState(false);

    // Get coordinates from the store
    const coordinates = useDroneStore((state) => state.coordinates) || {}; // Fallback to an empty object if coordinates are undefined

    useEffect(() => {
        const fetchWaypoints = () => {
            const ros = new ROSLIB.Ros({
                url: `${process.env.REACT_APP_ROS_URL}`
            });

            ros.on('error', (error) => {
                console.error('ROS connection error:', error);
                setError("Failed to connect to Drone.");
                setMessage("Failed to connect to Drone.");
            });

            const waypointPullTopic = new ROSLIB.Service({
                ros: ros,
                name: '/mavros/mission/pull', // Replace with your correct service name
                serviceType: 'mavros_msgs/WaypointPull' // Adjust service type
            });

            const waypointRequest = new ROSLIB.ServiceRequest({
                // Optionally add any parameters here that your service requires
            });

            waypointPullTopic.callService(waypointRequest, (response) => {
                if (response.success) {
                    console.log("Fetched waypoints:", response);
                    const fetchedWaypoints = response.waypoints
                        .filter((wp) => wp.command === 16)
                        .map((wp) => ({
                            lat: wp.x_lat,
                            lon: wp.y_long,
                            alt: wp.z_alt
                        }));

                    // Add coordinates from the store to the waypoints
                    if (coordinates.latitude && coordinates.longitude) {
                        fetchedWaypoints.push({
                            lat: coordinates.latitude,
                            lon: coordinates.longitude,
                            alt: 0, // You can set a default altitude or fetch it from somewhere
                        });
                    }

                    setWaypoints(fetchedWaypoints);
                } else {
                    setError("Failed to fetch waypoints.");
                }
                setLoading(false);
            }, (error) => {
                console.error('Service call failed:', error);
                setError("Error fetching waypoints.");
                setLoading(false);
            });
        };

        fetchWaypoints();
    }, [coordinates]); // Add `coordinates` as a dependency to refetch if it changes

    const waypointsForMap = waypoints.map((wp) => [wp.lon, wp.lat]); // Prepare the coordinates for the map

    return (
        <div className="dialogOverlay">
            <div className="dialogContent">
                <h2>Confirm Launch</h2>
                {loading && <p className="errorMessage">{message}</p>}
                {/* {error && <p className="errorMessage">{error}</p>} */}
                {!loading && !error && (
                    <div>
                        <p>Confirm the following waypoints before launching:</p>
                    </div>
                )}

                <div className="mapSection">
                    <MapClickable
                        initialZoom={16}
                        center={[waypoints[0]?.lon || 0.0, waypoints[0]?.lat || 0.0]} // Default center to the first waypoint
                        lineCoordinates={waypointsForMap} // Pass waypoints as line coordinates
                        onClick={(lng, lat) => console.log("Clicked on map:", lng, lat)} // Handle map clicks
                        clickable={false}
                    />
                </div>

                <div className="dialogActions">
                    <button
                        onClick={onConfirm}
                        disabled={loading || error !== null}
                        className="confButton"
                    >
                        Launch
                    </button>
                    <button onClick={onClose} className="cancButton">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};
