// socketHandler.js
import socket from './socket';
import { useNotificationStore } from '../store/notificationStore';

export const setupSocketListeners = () => {
  const addNotification = useNotificationStore.getState().addNotification;

  socket.on('delivery_schedule', (data) => {
    // Push the notification into Zustand
    addNotification({
      id: data.deliveryId,
      message: data.message,
      timestamp: new Date(),
    });
  });

  // Clean up listeners (optional, for unmounting)
  return () => {
    socket.off('delivery_schedule');
  };
};
