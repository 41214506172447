    import React, { useRef, useEffect, useState } from 'react';
    import mapboxgl from 'mapbox-gl';
    import 'mapbox-gl/dist/mapbox-gl.css';
    import '../style/map.css';

    mapboxgl.accessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHJ5NzVxaWwxbWhoMnJyejM4aTNlemM0In0.GLuB4QgKV5eMCKoq5PsPBw';

    const MapClickable = ({ initialZoom = 15, center, lineCoordinates = [], onClick }) => {
        const mapRef = useRef()
        const mapContainerRef = useRef()
        const markerRef = useRef()

        useEffect(() => {
            // TODO: Marker initial location is the current drone coordinate.
            mapboxgl.accessToken = 'pk.eyJ1Ijoic291dGhlcm50dyIsImEiOiJjbHJ5NzVxaWwxbWhoMnJyejM4aTNlemM0In0.GLuB4QgKV5eMCKoq5PsPBw'
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/satellite-v9',
                center: center,
                zoom: initialZoom,
            });

            mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

            markerRef.current = new mapboxgl.Marker()
            .setLngLat(center)
            .addTo(mapRef.current);
            mapRef.current.on('click', (event) => {
                const { lng, lat } = event.lngLat;
    
                markerRef.current.setLngLat([lng, lat]);
        
                if (onClick) {
                onClick(lng, lat);
                }
            });
            return () => {
                mapRef.current.remove()
            }
        }, [])


    return (
        <div>
        <div className='map-container' ref={mapContainerRef} />
        </div>
    );
    };

    export default MapClickable;
