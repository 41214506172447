import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MapClickable from "../mapClickable";
import useDroneStore from '../../store/droneStore'; // Import the Zustand store

export const AddLocations = ({ handleClick, handleRefresh, rosWebSocketUrl, droneTopic }) => {
    const [name, setName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [altitude, setAltitude] = useState("");
    const [description, setDescription] = useState("");
    const [arucoID, setArucoID] = useState("");
    const [arucoPicture, setArucoPicture] = useState("");
    const [coordinateMode, setCoordinateMode] = useState("manual");

    const coordinates = useDroneStore((state) => state.coordinates) || []; // Fallback to an empty array if coordinates are undefined or null

    const [markers, setMarkers] = useState([]);
    const [currentLat, currentLng] = coordinates;
    
    // Initialize map state
    const [mapCenter, setMapCenter] = useState([currentLng, currentLat]);

    const mapHandleClick = (lng, lat) => {
        setLatitude(lat);
        setLongitude(lng);
        console.log(`Clicked Coordinates: Longitude: ${lng}, Latitude: ${lat}`);
      };
    
    // Function to fetch drone coordinates
    const getDroneCoordinates = () => {
        const [lat, lng] = coordinates;
        setLatitude(lat);
        setLongitude(lng);
    };

    // Function to add new landing point
    const AddLocations = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/landing/landing_points`, {
                name,
                lat: latitude,
                long: longitude,
                altitude: parseFloat(altitude),
                description,
                aruco_id: 2, // Hardcoded for now
                headers: {
                    'ngrok-skip-browser-warning': true,  // Add this custom header
                }
            });
            if (response.status === 201) {
                handleRefresh();
                handleClick();
            }
        } catch (error) {
            console.error(error.response.data);
        }
    };

    // BETA: -7.0136531, 107.5716212


    const parsedLatitude = parseFloat(latitude) || currentLat || -7.0136531; // Fallback to default value
    const parsedLongitude = parseFloat(longitude) || currentLng || 107.5716212; // Fallback to default value


    return (
        <div className="updateTask">
            <div className="formTask">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark} className="logoExit" />
                </button>

                <div className="mainContent-update">
                    <h1>Add New Landing Point</h1>
                    <form className="formAddWay" onSubmit={AddLocations}>
                        <table className="tabelEdit">
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">1. Landing Point Name</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Name"
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">2. Coordinate Input Mode</h2>
                                    <select
                                        value={coordinateMode}
                                        onChange={(e) => setCoordinateMode(e.target.value)}
                                    >
                                        <option value="manual">Manual Input</option>
                                        <option value="map">Map Input</option>
                                        <option value="drone">Current Drone Coordinates</option>
                                    </select>
                                </td>
                            </tr>
                            {coordinateMode === "manual" && (
                                <>
                                    <tr>
                                        <td>
                                            <h2 className="labelField labelWay">3. Latitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Input Latitude"
                                                required
                                                value={latitude}
                                                onChange={(e) => setLatitude(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h2 className="labelField labelWay">4. Longitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Input Longitude"
                                                required
                                                value={longitude}
                                                onChange={(e) => setLongitude(e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                            {coordinateMode === "drone" && (
                                <>
                                    <tr>
                                        <td>
                                            <button
                                                type="button"
                                                className="getCoordinatesButton"
                                                onClick={getDroneCoordinates}
                                            >
                                                Get Drone Coordinates
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h2 className="labelField labelWay">3. Latitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Latitude will appear here"
                                                readOnly
                                                value={latitude}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h2 className="labelField labelWay">4. Longitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Longitude will appear here"
                                                readOnly
                                                value={longitude}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                            {coordinateMode === "map" && (
                                <>
                                    <MapClickable
                                        markers={[{ coordinates: [parsedLongitude, parsedLatitude] }]}
                                        center={[parsedLongitude, parsedLatitude]}
                                        onClick={mapHandleClick}
                                    />                                
                                <tr>
                                        <td>
                                            <h2 className="labelField labelWay">3. Latitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Latitude will appear here"
                                                readOnly
                                                value={latitude}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h2 className="labelField labelWay">4. Longitude</h2>
                                            <input
                                                type="text"
                                                placeholder="Longitude will appear here"
                                                readOnly
                                                value={longitude}
                                            />
                                        </td>
                                    </tr> 
                                </>
                            )}
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">5. Altitude</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Altitude"
                                        required
                                        onChange={(e) => setAltitude(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField">6. Description</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Description"
                                        required
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField">7. Upload ArUco Marker Picture</h2>
                                    <div className="file-upload-box">
                                        <label htmlFor="file-upload" className="custom-file-upload">
                                            Select File
                                        </label>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="hidden-input"
                                            accept="image/*"
                                            onChange={(e) => setArucoPicture(e.target.files[0])}
                                        />
                                        {arucoPicture ? (
                                            <p>{arucoPicture.name}</p>
                                        ) : (
                                            <p className="file-upload-text">No file selected</p>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField">8. Input ArUco Marker ID</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Unique Number 1-100"
                                        value={arucoID}
                                        onChange={(e) => setArucoID(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="buttonSection">
                                    <input type="submit" />
                                    <button className="cancelButton" onClick={handleClick}>
                                        Cancel
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    );
};
