import { useEffect, useState } from "react";
import "../../style/addLanding.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import React from 'react';
import baseUrl from '../../config.js';

export const EditLocations = ({ handleClick, handleRefresh, data }) => {
    const [name, setName] = useState(data.name);
    const [code, setCode] = useState(data.code);
    const [latitude, setLatitude] = useState(data.latitude);
    const [longitude, setLongitude] = useState(data.longitude);
    const [altitude, setAltitude] = useState(data.altitude);
    const [status, setStatus] = useState(data.status);
    const [arucoID, setArucoID] = useState(data.arucoID);
    const [arucoPicture, setArucoPicture] = useState('');

    const updateLocation = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${baseUrl}/waypoints/${data.id}`, {
                name,
                code,
                latitude,
                longitude,
                altitude,
                status,
                arucoID,
            });

            if (response.status === 200) {
                handleRefresh();
                handleClick();
            }
        } catch (error) {
            console.log(error.response.data);
        }
    }

    return (
        <div className="updateTask">
            <div className="formTask">
                <button onClick={handleClick} className="exit">
                    <FontAwesomeIcon icon={faXmark} className="logoExit" />
                </button>

                <div className="mainContent-update">
                    <h1>Edit Landing Point</h1>
                    <form className="formAddWay" onSubmit={updateLocation}>
                        <table className="tabelEdit">
                            <tr className="latInput">
                                <td>
                                    <h2 className="labelField labelWay">1. Landing Point Name</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Name"
                                        required="required"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                </td>
                            </tr>
                            <div className="locationCoordinate">
                                <tr className="latInput">
                                    <td>
                                        <h2 className="labelField labelWay">2. Latitude Value</h2>
                                        <input
                                            type="text"
                                            placeholder="Input Latitude"
                                            required="required"
                                            value={latitude}
                                            onChange={(e) => setLatitude(e.target.value)} />
                                    </td>
                                </tr>
                                <tr className="lonInput">
                                    <td>
                                        <h2 className="labelField labelWay">3. Longitude Value</h2>
                                        <input
                                            type="text"
                                            placeholder="Input Longitude"
                                            required="required"
                                            value={longitude}
                                            onChange={(e) => setLongitude(e.target.value)} />
                                    </td>
                                </tr>
                            </div>
                            <tr>
                                <td>
                                    <h2 className="labelField labelWay">4. Altitude Value</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Altitude"
                                        required="required"
                                        value={altitude}
                                        onChange={(e) => setAltitude(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField">5. Upload ArUco Marker Picture</h2>
                                    <div className="file-upload-box">
                                        <label htmlFor="file-upload" className="custom-file-upload">
                                            Select File
                                        </label>
                                        <input
                                            id="file-upload"
                                            type="file"
                                            className="hidden-input"
                                            accept="image/*"
                                            onChange={(e) => setArucoPicture(e.target.files[0])} />
                                        {arucoPicture ? (
                                            <p>{arucoPicture.name}</p>
                                        ) : (
                                            <p className="file-upload-text">No file selected</p>
                                        )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="labelField">6. Input ArUco Marker ID</h2>
                                    <input
                                        type="text"
                                        placeholder="Input Unique Number 1-100"
                                        required="required"
                                        value={arucoID}
                                        onChange={(e) => setArucoID(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td className="buttonSection">
                                    <input type="submit" value="Update" />
                                    <button className="cancelButton" onClick={handleClick}>Cancel</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    );
}
